<script setup lang='ts'>
import { CaretLeftOutlined, CaretRightOutlined, DownloadOutlined } from '@ant-design/icons-vue'
import SvgIcon from '@/components/svg-icon/index.vue'
import { useDownloadImage } from '~/hooks/useDownloadImage'
// import { type EmittImage, EmittImageKey, type ImageInfo } from '@/emitter/image'

const props = withDefaults(defineProps<{
  imageUrl: string
}>(), {
  imageUrl: '',
})

const { t } = useI18n()

const tools = [
  /* 下载 */
  {
    label: t('draw.task.toolbar.download'),
    key: 'DownImage',
    type: 0,
    icon: () => h(DownloadOutlined, {
      style: { fontSize: '18px' },
    }),
  },
  /* 发送局部重绘 */
  {
    label: `${t('draw.task.toolbar.send')} ${t('draw.menu.inpainting')}`,
    key: 'PartialRedraw',
    type: 3,
    icon: () => h(SvgIcon, { name: 'inpaintAnything', style: { fontSize: '16px' } }),
  },
  /* 图生图 */
  {
    label: `${t('draw.task.toolbar.send')} ${t('draw.menu.image_to_image')}`, // 发送图生图
    key: 'ImageToImage',
    type: 2,
    icon: () => h(SvgIcon, { name: 'img2img', style: { fontSize: '16px' } }),
  },
  {
    /* 外扩图 */
    label: `${t('draw.task.toolbar.send')} ${t('draw.menu.extender')}`, // 发送外扩图
    key: 'OutwardExpansion',
    type: 4,
    icon: () => h(SvgIcon, { name: 'outward', style: { fontSize: '16px' } }),
  },
  {
    /* 智能擦除 */
    label: `${t('draw.task.toolbar.send')} ${t('draw.menu.erase')}`, // 发送智能擦除
    key: 'IntelligentErase',
    type: 5,
    icon: () => h(SvgIcon, { name: 'eraserLayout', style: { fontSize: '16px' } }),
  },
  {
    /* 图像超分 */
    label: `${t('draw.task.toolbar.send')} ${t('draw.menu.enlarger')}`, // 发送图像超分
    key: 'ImageEnhance',
    type: 6,
    icon: () => h(SvgIcon, { name: 'imageEnhance', style: { fontSize: '16px' } }),
  },
  {
    /* 换发型 */
    label: `${t('draw.task.toolbar.send')} ${t('draw.menu.hair')}`, // 发送人物换发型
    key: 'HairPaint',
    type: 12,
    icon: () => h(SvgIcon, { name: 'hairStyle', style: { fontSize: '16px' } }),
  },
  {
    /* 人像换脸 */
    label: `${t('draw.task.toolbar.send')} ${t('draw.menu.facial')}`, // 发送人物换脸
    key: 'PortraitFacelift',
    type: 13,
    icon: () => h(SvgIcon, { name: 'faceChang', style: { fontSize: '16px' } }),
  },
  {
    /* 个性化写真 */
    label: `${t('draw.task.toolbar.send')} ${t('draw.menu.portrait')}`, // 发送个性化写真
    key: 'PersonalizedPs',
    type: 14,
    icon: () => h(SvgIcon, { name: 'certificates', style: { fontSize: '16px' } }),
  },
  {
    /* 图转文 */
    label: `${t('draw.task.toolbar.send')} ${t('draw.menu.image_to_prompt')}`, // 发送图转文
    key: 'ImageToText',
    type: 15,
    icon: () => h(SvgIcon, { name: 'imageToText', style: { fontSize: '16px' } }),
  },
]
const router = useRouter()
const taskStore = useTaskStore()
// const mittImage = inject<EmittImage>(EmittImageKey)

const { downloadImageAsFile } = useDownloadImage()

const toolsPerPage = 6
const currentPage = shallowRef(0)

const totalPages = computed(() => Math.ceil(tools.length / toolsPerPage))

const isFirstPage = computed(() => currentPage.value === 0)
const isLastPage = computed(() => currentPage.value === totalPages.value - 1)

const displayedToolPairs = computed(() => {
  const startIndex = currentPage.value * toolsPerPage
  const pageTools = tools.slice(startIndex, startIndex + toolsPerPage)
  const pairs = []
  for (let i = 0; i < pageTools.length; i += 2) {
    pairs.push(pageTools.slice(i, i + 2))
  }
  return pairs
})

const changePage = (direction: number) => {
  const newPage = currentPage.value + direction
  if (newPage >= 0 && newPage < totalPages.value) {
    currentPage.value = newPage
  }
}

const resetCurrentPage = () => {
  if (currentPage.value === 0) {
    return
  }
  currentPage.value = 0
}

// const emitImage = (url: ImageInfo['url']) => {
//   mittImage?.emitImage({ url })
// }

const downImage = (url: string) => {
  if (!url)
    return
  let targetUrl
  const isDev = import.meta.env.DEV
  if (isDev) {
    const urlObject = new URL(url)
    targetUrl = `${urlObject.pathname}${urlObject.search}`
  }
  else {
    targetUrl = url
  }
  downloadImageAsFile(targetUrl)
}

const handleToolsAction = (tool: any) => {
  switch (tool.key) {
    case 'DownImage':
      downImage(props.imageUrl)
      break
    default:
      /* router.push({ name: tool.key }).then(async () => {
        emitImage(props.imageUrl)
        await new Promise(resolve => setTimeout(resolve, 1000))
        emitImage(props.imageUrl)
      }) */

      /* router.push({ name: tool.key }).then(async () => {
        taskStore.setPendingImageUrl(props.imageUrl)
        await new Promise(resolve => setTimeout(resolve, 1000))
        taskStore.clearPendingImageUrl()
      }) */

      router.push({ name: tool.key }).then(() => {
        taskStore.setPendingImageUrl(props.imageUrl)
      })
  }
}

defineExpose({
  resetCurrentPage,
})
</script>

<template>
  <div class="px-1 w-full items-center justify-center h-[40%] absolute bottom-1 flex gap-2">
    <a-button type="text" shape="circle" :disabled="isFirstPage" @click.stop="changePage(-1)">
      <template #icon>
        <CaretLeftOutlined :class="[isFirstPage ? 'text-gray' : 'text-white']" />
      </template>
    </a-button>

    <div class="flex-1 h-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2px">
      <div v-for="(pair, pairIndex) in displayedToolPairs" :key="pairIndex" class="flex flex-col">
        <a-tooltip v-for="(tool, toolIndex) in pair" :key="tool.key" trigger="hover" color="#949DAB" :title="tool.label" :placement="toolIndex === 0 ? 'top' : 'bottom'">
          <div
            class="bg-[rgba(0,0,0,0.5)] flex-1 overflow-hidden flex items-center justify-center" :class="[
              toolIndex === 0 ? 'mb-[1px]' : 'mt-[1px]',
            ]"
            @click.stop="handleToolsAction(tool)"
          >
            <component :is="tool.icon()" />
          </div>
        </a-tooltip>
      </div>
    </div>

    <a-button type="text" shape="circle" :disabled="isLastPage" @click.stop="changePage(1)">
      <template #icon>
        <CaretRightOutlined :class="[isLastPage ? 'text-gray' : 'text-white']" />
      </template>
    </a-button>
  </div>
</template>

<style lang='less' scoped>

</style>
