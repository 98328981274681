import { createRouter, createWebHistory } from 'vue-router'
import staticRoutes from './static-routes'

const router = createRouter({
  routes: [
    ...staticRoutes,
  ],
  history: createWebHistory(import.meta.env.VITE_APP_BASE),
  scrollBehavior: () => {
    return new Promise((resolve) => {
      resolve({ left: 0, top: 0 })
    })
  },
})

export default router
