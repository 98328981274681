import { message } from 'ant-design-vue'
import type { MenuData } from '~@/layouts/basic-layout/typing'
import { genMenus } from '~@/router/generate-route'
import { openRoutes } from '~@/router/menu-routes'
import { userInfoApi } from '~/api/userInfo'
import { logoutApi } from '~/api/login'
import defaultAvatar from '~/assets/images/avatar.png'
import type { UserInfo } from '~/api/userInfo'

export const useUserStore = defineStore('user', () => {
  const token = useAuthorization()
  const authCode = useAuthorizationCode()
  const menuData = shallowRef<MenuData>(genMenus(openRoutes))
  const userInfo = shallowRef<UserInfo>()
  const avatar = computed(() => userInfo.value?.avatar || defaultAvatar)
  const username = computed(() => userInfo.value?.accountInfo || '')
  const roles = computed(() => userInfo.value?.roles || [])
  const permissions = computed(() => userInfo.value?.permissions || [])
  const balance = computed(() => userInfo.value?.accountAvailableCount || 0)
  const loginModalOpen = shallowRef(false)
  const letterModalOpen = shallowRef(false)
  const insufficientModalOpen = shallowRef(false)
  const isLogin = computed(() => !!userInfo.value)

  const openLoginModal = () => {
    if (loginModalOpen.value)
      return
    loginModalOpen.value = true
  }

  const openLetterModal = () => {
    if (letterModalOpen.value)
      return
    letterModalOpen.value = true
  }

  const openInsufficientModal = () => {
    if (insufficientModalOpen.value)
      return
    insufficientModalOpen.value = true
  }

  const getUserInfo = async () => {
    try {
      const resutl = await userInfoApi()
      if (resutl.code === '200')
        userInfo.value = resutl.data
      return resutl
    }
    catch (error) {
      console.log(error)
    }
  }

  const clearUserInfo = () => {
    token.value = null
    authCode.value = null
    userInfo.value = undefined
  }

  const logout = async () => {
    // 退出登录
    // 1. 清空用户信息
    try {
      const { code, msg } = await logoutApi()
      if (code !== '200')
        message.error(msg)
    }
    finally {
      clearUserInfo()
    }
  }

  return {
    userInfo,
    menuData,
    avatar,
    roles,
    permissions,
    username,
    balance,
    getUserInfo,
    logout,
    loginModalOpen,
    openLoginModal,
    letterModalOpen,
    openLetterModal,
    openInsufficientModal,
    insufficientModalOpen,
    clearUserInfo,
    isLogin,
  }
})
