import mitt, { type Emitter } from 'mitt'
import type { InjectionKey } from 'vue'
import type { DrawTaskInfo } from '~@/api/draw'

export interface TaskInfo {
  taskCode: string
  // info: 从DrawTaskInfo选取createTime 和 prompt， 并改为可选
  info?: Pick<Partial<DrawTaskInfo>, 'createTime' | 'prompt'>
}

interface EventMap {
  taskInfo: TaskInfo
}

type MittEventMap = {
  [K in keyof EventMap]: EventMap[K];
}

export interface EmittTask {
  emitTask: (taskInfo: TaskInfo) => void
  onTask: (callback: (taskInfo: TaskInfo) => void) => void
  offTask: (callback: (taskInfo: TaskInfo) => void) => void
}

// 创建 emitter 实例
const emitter: Emitter<MittEventMap> = mitt<MittEventMap>()

// 使用 Symbol 来确保 key 的唯一性
// export const EmittTaskKey: InjectionKey<Emitter<MittEventMap>> = Symbol('emittTask')
// 使用 Symbol 来确保 key 的唯一性
export const EmittTaskKey: InjectionKey<EmittTask> = Symbol('emittTask')

// 触发 'task' 事件
// const emitterTask = (task: string) => {
//   emitter.emit('task', task)
// }

// 触发 'taskInfo' 事件
const emitTask = (taskInfo: TaskInfo) => {
  emitter.emit('taskInfo', taskInfo)
}

// 监听 'task' 事件
// const onTask = (callback: (task: string) => void) => {
//   emitter.on('task', callback)
// }

// 监听 'taskInfo' 事件
const onTask = (callback: (taskInfo: TaskInfo) => void) => {
  emitter.on('taskInfo', callback)
}

// 移除 'task' 事件
// const offTask = (callback: (task: string) => void) => {
//   emitter.off('task', callback)
// }

// 移除 'taskInfo' 事件
const offTask = (callback: (taskInfo: TaskInfo) => void) => {
  emitter.off('taskInfo', callback)
}

export function provideEmittTask() {
  return {
    emitTask,
    onTask,
    offTask,
  }
}
