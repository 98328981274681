import { AxiosError } from 'axios'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
import { Button, notification } from 'ant-design-vue'
import router from '~/router'
import { Updater } from '~/utils/update-website'

// const allowList = ['/home', '/login', '/error', '/401', '/404', '/403']
// const loginPath = '/login'

const updater = new Updater()

const updateModal = () => {
  const { t } = useI18nLocale()
  const key = `open${Date.now()}`
  notification.config({
    maxCount: 1,
  })
  notification.open({
    duration: null,
    icon: () => h(ExclamationCircleOutlined, { style: 'color: #ffa900' }),
    message: t('message.version.title'), // 发现新版本
    description: t('message.version.desc'), // 检测到到最新版本，刷新后立即使用
    btn: () => h(Button, {
      type: 'primary',
      size: 'small',
      onClick: () => window.location.reload(),
    }, {
      default: () => t('message.version.btn'), // 立即刷新
    }),
    key,
    onClose: () => {},
  })
}

/* router.beforeEach(async (to, _, next) => {
  const userStore = useUserStore()
  const token = useAuthorization()
  if (!token.value) {
    //  如果token不存在就跳转到登录页面
    if (!allowList.includes(to.path) && !to.path.startsWith('/redirect')) {
      next({
        path: loginPath,
        query: {
          // redirect: encodeURIComponent(to.fullPath),
        },
      })
      return
    }
  }
  else {
    if (!userStore.userInfo && !allowList.includes(to.path) && !to.path.startsWith('/redirect')) {
      try {
        // 获取用户信息
        const result = await userStore.getUserInfo()
        // 获取路由菜单的信息
        // await userStore.generateDynamicRoutes()
        if (result?.code === '401') {
          // 跳转到error页面
          next({
            path: '/401',
          })
        }
        else if (result === undefined) {
          next({
            path: '/error',
          })
        }
        else {
          // console.log('to', to)
          next({
            ...to,
            replace: true,
          })
        }
        return
      }
      catch (e) {
        if (e instanceof AxiosError && e?.response?.status === 401) {
          // 跳转到error页面
          next({
            path: '/401',
          })
        }
      }
    }
    else {
      // 如果当前是登录页面就跳转到首页
      if (to.path === loginPath) {
        next({
          path: '/',
        })
        return
      }
    }
  }
  next()
}) */

function handleAuthParam(param: string, value: string) {
  switch (param) {
    case 'accessToken': {
      const token = useAuthorization()
      token.value = value
      break
    }
    case 'code': {
      const code = useAuthorizationCode()
      code.value = value
      break
    }
    case 'newUser': {
      const userStore = useUserStore()
      setTimeout(() => {
        userStore.openLetterModal()
      }, 1000)
    }
  }
}

router.beforeEach((to, _, next) => {
  const { locale } = useI18nLocale()
  if (locale.value === 'en-US') {
    const googleRedirctParams = ['accessToken', 'code', 'newUser']
    let hasAuthParams = false
    const newQuery = { ...to.query }

    googleRedirctParams.forEach((param) => {
      if (to.query[param]) {
        hasAuthParams = true
        // 处理参数
        handleAuthParam(param, to.query[param] as string)
        // 从 URL 中删除参数
        delete newQuery[param]
      }
    })

    if (hasAuthParams) {
      // 如果有认证参数，更新路由但保持在同一页面
      next({ ...to, query: newQuery, replace: true })
      return
    }
  }

  next()
})

// 路由拦截器
router.beforeResolve(async () => {
  const userStore = useUserStore()
  const token = useAuthorization()
  // const isAllowed = allowList.some(path => to.path.startsWith(path))
  if (token.value && !userStore.userInfo) {
    try {
      await userStore.getUserInfo()
      console.log('userStore', userStore.userInfo)
      return true
    }
    catch (error) {
      if (error instanceof AxiosError) {
        // ... 处理错误，然后取消导航
        return false
      }
      else {
        // 意料之外的错误，取消导航并把错误传给全局处理器
        throw error
      }
    }
  }
})

router.afterEach((to) => {
  // TODO
  // const title = to.meta?.title
  // if (title)
  //   document.title = title as string
  useMetaTitle(to)
  // 判断是不是开发环境
  if (import.meta.env.DEV)
    return
  updater.checkUpdate().then((result: any) => {
    const version = sessionStorage.getItem('version')
    const newVersion = result?.number
    if (!newVersion)
      return
    if (!version) {
      sessionStorage.setItem('version', newVersion)
      return
    }
    if (version !== newVersion) {
      updateModal()
      sessionStorage.setItem('version', newVersion)
    }
  }).catch((error) => {
    console.error('检查更新时发生错误:', error)
  })
})
