import { usePostAes } from '~@/composables/api'

const BASE_URL = '/AIGCImgComServ'

export interface MsgCode {
  messageChannelType: number // 登录方式： 1-飞书、 2-短信、 3-邮箱
  terminalId: string // 手机号、邮箱、飞书的唯一标识
  applicationType: number // 应用类型： 1-PS、 2-web
}

/**
 * @description: 验证码发送
 */
export const msgCode = (params: MsgCode) => {
  return usePostAes<{ tenantId: number }, MsgCode>(`${BASE_URL}/user/ignore/send/verifyCode/v1`, params)
}

export interface LoginParams {
  channelType: number // 登录方式： 1-飞书、 2-短信、 3-邮箱
  terminalId: string // 手机号、邮箱、飞书的唯一标识
  verifyCode: string // 验证码
  tenantId: number // 租户ID
  agreement: boolean
  applicationType: number // 应用类型： 1-PS、 2-web
}

export interface GoogleLoginParams {
  channelType: number // 登录方式： 1-飞书、 2-短信、 3-邮箱 、6 Google渠道
  applicationType: number // 应用类型： 1-PS、 2-web
  browserRedirectUrl: string
}

/**
 * @description: 用户登录接口
 */
export const loginApi = (params: LoginParams) => {
  return usePostAes<{ code: string, token: string, newUser: boolean }, LoginParams>(`${BASE_URL}/user/ignore/login/v2`, params)
}

/**
 * @description: 退出登录
 */
export const logoutApi = () => {
  return usePost(`${BASE_URL}/user/logout/v1`)
}

/*
 * 获取Google 重定向接口
  */
export const googleUrl = (params: GoogleLoginParams) => {
  return usePost<{ info: string }, GoogleLoginParams>(`${BASE_URL}/user/ignore/login/v1`, params)
}
