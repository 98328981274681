<script setup lang='ts'>
import ImageGroup from './image-group.vue'
import type { DrawTaskInfo } from '~@/api/draw'
// import avatar from '@/assets/images/avatar.png'
import { copyText } from '~@/utils/methods'

/* 把DrawTaskInfo 里面全部的属性转为可选 */
type DrawTaskInfoParams = Partial<DrawTaskInfo>

type Props = DrawTaskInfoParams & {
  loading?: boolean
}

withDefaults(defineProps<Props>(), {
  loading: false,
  taskType: 0,
  taskStatus: undefined,
  taskCode: '',
  prompt: '',
  result: () => [],
  styleIdentifyResult: () => [],
  createTime: '',
  progress: 0,
  caption: '',
  failReason: '',
})

const { t } = useI18n()

const colors: Record<number, { label: string, color: string }> = {
  1: { label: t('draw.menu.text_to_image'), color: '#A6AFFE' }, // 文生图
  2: { label: t('draw.menu.image_to_image'), color: '#EBB094' }, // 图生图
  3: { label: t('draw.menu.inpainting'), color: '#58B7CC' }, // 局部重绘
  4: { label: t('draw.menu.extender'), color: '#E4C73B' }, // 外扩图
  5: { label: t('draw.menu.erase'), color: '#6A88D6' }, // 智能擦除
  6: { label: t('draw.menu.enlarger'), color: '#E27C75' }, // 图像超分
  7: { label: '风格识别', color: '#ACC071' },
  12: { label: t('draw.menu.hair'), color: '#E3B36B' }, // 人物换发型
  13: { label: t('draw.menu.facial'), color: '#DE5A81' }, // 人物换脸
  14: { label: t('draw.menu.portrait'), color: '#6936F5' }, // 个性化写真
  15: { label: t('draw.menu.image_to_prompt'), color: '#5AC086' }, // 图转文
  17: { label: t('draw.menu.flux-ai-image'), color: '#9bc280' }, // Flux生图
}

const getColor = (taskType: number | null) => {
  return colors[taskType ?? 0] ?? { label: '未知任务', color: '#E35456' }
}

const handleClickCode = (e: MouseEvent) => {
  const el = e.target as HTMLElement
  el?.textContent && copyText(el.textContent)
  const selection = window?.getSelection()
  selection?.selectAllChildren(el)
}
</script>

<template>
  <div class="task-item-wrapper">
    <a-skeleton v-if="loading" avatar :paragraph="{ rows: 4 }" />
    <div v-else class="task-item">
      <div class="task-info">
        <!-- <a-avatar :src="avatar" :size="48" /> -->
        <SvgIcon name="botAvatar" class="text-10" />
        <div class="info">
          <div class="name">
            <!-- 智启特AI -->
            {{ t('app.name') }}
          </div>
          <div class="type-tag">
            <a-tag class="mr-0!" :color="getColor(taskType).color">
              {{ getColor(taskType).label }}
            </a-tag>
          </div>
          <div class="time">
            {{ createTime }}
          </div>
          <div>
            <a-tooltip trigger="hover" color="#949DAB" placement="top" class="task-tooltip">
              <template #title>
                <div>ID:</div>
                <div style="white-space: nowrap; cursor: copy;" @click="handleClickCode">
                  {{ taskCode }}
                </div>
              </template>
              <a-button shape="circle" type="link">
                <template #icon>
                  <SvgIcon name="infoCircle" style="font-size: 16px;" />
                </template>
              </a-button>
            </a-tooltip>
          </div>
        </div>
      </div>
      <div class="task-log">
        <!-- 任务结果 -->
        <div class="task-result">
          <div class="description">
            {{ prompt }}
          </div>
          <ImageGroup :images="result" />
          <div class="img-to-text">
            {{ caption }}
          </div>
        </div>
        <!-- 任务进度 -->
        <div v-if="progress && !(result && result.length)" class="progress">
          <a-progress
            :stroke-color="{
              '0%': '#2466eb',
              '100%': '#79cfff',
            }"
            :percent="progress" status="active"
          />
        </div>
        <!-- 生图失败 -->
        <div v-if="taskStatus === 0 || taskStatus === 6" class="task-fail">
          <div class="fail-box">
            <SvgIcon name="fail" class="fail-icon" />
            <div class="fail-msg" :title="failReason || t('draw.task.hint.failed')">
              {{ failReason || t('draw.task.hint.failed') }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang='less' scoped>
.task-item-wrapper {
  width: 100%;
  height: auto;
  padding: 16px 16px;
  border-radius: 6px;
  background: #f9f9fa;
}
.task-item {
  width: 100%;
  height: fit-content;
  .task-info {
    display: flex;
    .info {
      padding: 0 22px;
      display: flex;
      align-items: center;
      gap: 16px;
      .name {
        font-size: 16px;
        font-weight: 800;
        background: linear-gradient(90deg, rgba(38, 108, 252, 1) 0%, rgba(161, 88, 253, 1) 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .time {
        color: #555555;
      }
    }
  }
  .task-log {
    padding-left: 64px;
    display: flex;
    flex-direction: column;
    .task-result {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding-bottom: 16px;
    }
    .img-to-text {
      font-size: 16px;
      line-height: 24px;
      color: #333333;
      margin-top: -16px;
    }
    .description {
      font-size: 16px;
      line-height: 24px;
      color: #333333;
    }

    .progress {
      width: 60%;
      padding: 16px 0;
    }
    .task-fail {
      width: 100%;
      font-size: 16px;
      margin-bottom: 16px;
      .fail-box {
        width: 220px;
        height: 220px;
        padding: 0 12px;
        border-radius: 6px;
        background: #e9eef5;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 12px;
        .fail-icon {
          font-size: 52px;
        }
        .fail-msg {
          width: 100%;
          font-size: 14px;
          color: #88a6cf;
          letter-spacing: 2px;
          text-align: center;
          // overflow: hidden;
          // white-space: nowrap;
          // text-overflow: ellipsis;
          // 超出两行显示省略
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;

        }
      }
    }
  }
}
</style>

<style lang='less'>
.ant-tooltip {
  max-width: none !important;
}
</style>
