<script setup lang="ts">
import { legacyLogicalPropertiesTransformer } from 'ant-design-vue'

const { antd } = useI18nLocale()

const appStore = useAppStore()
</script>

<template>
  <a-config-provider
    :theme="appStore.theme"
    :locale="antd"
  >
    <a-style-provider hash-priority="high" :transformers="[legacyLogicalPropertiesTransformer]">
      <RouterView />
    </a-style-provider>
  </a-config-provider>
</template>
