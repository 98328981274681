export const STORAGE_AUTHORIZE_KEY = 'Authorization'
export const STORAGE_AUTHORIZE_CODE_KEY = 'Code'

const devToken = import.meta.env.VITE_API_TOKEN || null
const devCode = import.meta.env.VITE_API_CODE || null

export const useAuthorization = createGlobalState(() => useStorage<null | string>(STORAGE_AUTHORIZE_KEY, devToken))

/* Code  */
export const useAuthorizationCode = createGlobalState(() => useStorage<null | string>(STORAGE_AUTHORIZE_CODE_KEY, devCode))
