// 通过菜单生成路由

import { isUrl } from '@v-c/utils'
import type { RouteRecordRaw } from 'vue-router'
import type { MenuData, MenuDataItem } from '~@/layouts/basic-layout/typing'
import { i18n } from '~@/locales'

let cache_key = 1

const getCacheKey = () => `Cache_Key_${cache_key++}`

function renderTitle(route: RouteRecordRaw) {
  const { title, locale } = route.meta || {}
  if (!title)
    return ''
  return locale ? (i18n.global as any).t(locale) : title
}

function formatMenu(route: RouteRecordRaw, path?: string): MenuDataItem {
  return {
    title: () => renderTitle(route) || '',
    icon: route.meta?.icon || '',
    path: path || route.path,
    hideInMenu: route.meta?.hideInMenu || false,
    parentKeys: route.meta?.parentKeys || [],
    isIframe: route.meta?.isIframe || false,
    hideInBreadcrumb: route.meta?.hideInBreadcrumb || false,
    hideChildrenInMenu: route.meta?.hideChildrenInMenu || false,
    keepAlive: route.meta?.keepAlive || false,
    name: route.name as string,
    url: route.meta?.url || '',
    target: route.meta?.target || '_blank',
  }
}

// 本地静态路由生成菜单的信息
export const genMenus = (routes: RouteRecordRaw[], parent?: MenuDataItem) => {
  const menuData: MenuData = []
  const { hasAccess } = useAccess()
  routes.forEach((route) => {
    if (route.meta?.access) {
      const isAccess = hasAccess(route.meta?.access)
      if (!isAccess)
        return
    }
    let path = route.path
    if (!path.startsWith('/') && !isUrl(path)) {
      // 判断当前是不是以 /开头，如果不是就表示当前的路由地址为不完全的地址
      if (parent)
        path = `${parent.path}/${path}`
      else
        path = `/${path}`
    }
    // 判断是不是存在name，如果不存在name的情况下，自动补充一个自定义的name，为了更容易的去实现保活的功能，name是必须的
    if (!route.name)
      route.name = getCacheKey()
    const item: MenuDataItem = formatMenu(route, path)
    item.children = []
    if (route.children && route.children.length)
      item.children = genMenus(route.children, item)
    if (item.children?.length === 0)
      delete item.children
    menuData.push(item)
  })
  return menuData
}
