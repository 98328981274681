<script setup lang='ts'>

</script>

<template>
  <a-row class="main-box">
    <a-col class="left-clo" :lg="{ span: 10 }" :xl="{ span: 9 }" :xxl="{ span: 8 }" span="7">
      <slot name="left" />
    </a-col>
    <a-col class="right-clo" :lg="{ span: 14 }" :xl="{ span: 15 }" :xxl="{ span: 16 }" span="17">
      <slot name="right" />
    </a-col>
  </a-row>
</template>

<style lang='less' scoped>
.main-box {
  flex: 1;
  height: 100%;
  width: 100%;
  padding: 12px 0 12px 6px;
  .left-clo {
    height: 100%;
    background-color: #fff;
    border-radius: 4px;
  }
  .right-clo {
    width: 100%;
    height: 100%;
    padding-left: 12px;
    position: relative;
  }
}
</style>
