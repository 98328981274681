import type { RouteRecordRaw } from 'vue-router'
import { basicRouteMap } from './router-modules'
import DrawLayout from '~/pages/draw-group/index.vue'

export const openRoutes: RouteRecordRaw[] = [
  {
    path: '/home',
    redirect: '/home/index',
    meta: {
      title: '首页',
      hideChildrenInMenu: true,
      locale: 'menu.home',
    },
    component: basicRouteMap.RouteView,
    children: [{
      path: '/home/index',
      name: 'HomePage',
      meta: {
        title: '赋能无限可能 共创智能未来',
        locale: 'menu.home.index',
        keepAlive: true,
        parentKeys: ['/home'],
      },
      component: () => import('~/pages/home/index.vue'),
    }],
  },
  {
    /* 绘图体验 */
    path: '/draw',
    name: 'Draw',
    redirect: '/draw/text-to-image',
    meta: {
      title: '绘图体验',
      locale: 'menu.playground',
      // redirect: '/draw/text-to-image',
    },
    component: DrawLayout,
    children: [{
      path: '/draw/flux-ai-image',
      name: 'FluxAIImage',
      meta: {
        title: 'Flux生图',
        locale: 'draw.menu.flux-ai-image',
      },
      component: () => import('~/pages/draw-group/flux/index.vue'),
    }, {
      path: '/draw/text-to-image',
      name: 'TextToImage',
      meta: {
        title: '文生图',
        locale: 'draw.menu.text_to_image',
      },
      component: () => import('~/pages/draw-group/text-to-img/index.vue'),
    }, {
      /* 图生图 */
      path: '/draw/image-to-image',
      name: 'ImageToImage',
      meta: {
        title: '图生图',
        locale: 'draw.menu.image_to_image',
      },
      component: () => import('~/pages/draw-group/img-to-img/index.vue'),
    }, {
      /* 局部重绘 */
      path: '/draw/partial-redraw',
      name: 'PartialRedraw',
      meta: {
        title: '局部重绘',
        locale: 'draw.menu.inpainting',
      },
      component: () => import('~/pages/draw-group/partial-redraw/index.vue'),
    }, {
      /* 外扩图 */
      path: '/draw/outward-expansion',
      name: 'OutwardExpansion',
      meta: {
        title: '外扩图',
        locale: 'draw.menu.extender',
      },
      component: () => import('~/pages/draw-group/outward-expansion/index.vue'),
    }, {
      /* 智能擦除 */
      path: '/draw/intelligent-erase',
      name: 'IntelligentErase',
      meta: {
        title: '智能擦除',
        locale: 'draw.menu.erase',
      },
      component: () => import('~/pages/draw-group/intelligent-erase/index.vue'),
    }, {
      /*  */
      path: '/draw/image-enhance',
      name: 'ImageEnhance',
      meta: {
        title: '图像超分',
        locale: 'draw.menu.enlarger',
      },
      component: () => import('~/pages/draw-group/image-enhance/index.vue'),
    }, {
      /* 个性化写真 */
      path: '/draw/personalized-ps',
      name: 'PersonalizedPs',
      meta: {
        title: '个性化写真',
        locale: 'draw.menu.portrait',
      },
      component: () => import('~/pages/draw-group/personalized-ps/index.vue'),
    }, {
      /* 人像换脸 */
      path: '/draw/portrait-facelift',
      name: 'PortraitFacelift',
      meta: {
        title: '人像换脸',
        locale: 'draw.menu.facial',
      },
      component: () => import('~/pages/draw-group/portrait-facelift/index.vue'),
    }, {
      /* 人物换发型 */
      path: '/draw/hair-paint',
      name: 'HairPaint',
      meta: {
        title: '人像换发型',
        locale: 'draw.menu.hair',
      },
      component: () => import('~/pages/draw-group/hair-paint/index.vue'),
    }, {
      /* 图转文 */
      path: '/draw/image-to-text',
      name: 'ImageToText',
      meta: {
        title: '图转文',
        locale: 'draw.menu.image_to_prompt',
      },
      component: () => import('~/pages/draw-group/image-to-text/index.vue'),
    }],
  },
  /* PS-AI  */
  /* {
    path: '/photoshop-ai',
    redirect: '/photoshop-ai/index',
    meta: {
      title: 'AI-PS插件',
      locale: 'menu.photoshop-ai',
      hideChildrenInMenu: true,
    },
    component: basicRouteMap.RouteView,
    children: [{
      path: '/photoshop-ai/index',
      name: 'PhotoShopAIIndex',
      meta: {
        title: 'PS插件',
        parentKeys: ['/photoshop-ai'],
      },
      component: () => import('~/pages/photoshop-ai/index.vue'),
    }],
  }, */
  /* 产品定价  */
  {
    path: '/product-pric',
    redirect: '/product-pric/index',
    meta: {
      title: '产品定价',
      locale: 'menu.price',
      hideChildrenInMenu: true,
    },
    component: basicRouteMap.RouteView,
    children: [{
      path: '/product-pric/index',
      name: 'ProductPric',
      meta: {
        title: '产品定价',
        parentKeys: ['/product-pric'],
      },
      component: () => import('~/pages/product-pric/index.vue'),
    }],
  },
  /* API文档 */
  {
    path: '/api-docs',
    name: 'ApiDocs',
    redirect: '/api-docs/image',
    meta: {
      title: 'API文档',
      locale: 'menu.api-docs',
      hideChildrenInMenu: true,
    },
    component: basicRouteMap.RouteView,
    children: [{
      path: '/api-docs/image',
      name: 'ApiDocsImage',
      meta: {
        title: 'API文档',
        url: 'https://zhiqiteai.apifox.cn/',
        parentKeys: ['/api-docs'],
      },
      beforeEnter: (to) => {
        const { locale } = useI18nLocale()
        if (locale.value.includes('en')) {
          to.meta.url = 'https://4v5l1y8wvl.apifox.cn/api-202222087'
        }
        return true
      },
      component: basicRouteMap.Iframe,
    }],
  },
  /* 隐私协议 */
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import('~/pages/common/privacy.vue'),
    meta: {
      title: '隐私协议',
      hideInMenu: true,
      locale: 'draw.menu.privacy',
    },
  },
  /* cookie政策 */
  {
    path: '/cookie-policy',
    name: 'CookiePolicy',
    component: () => import('~/pages/common/cookie-policy.vue'),
    meta: {
      title: 'cookie policy',
      hideInMenu: true,
    },
  },
  /* 服务条款 */
  /* cookie政策 */
  {
    path: '/service-terms',
    name: 'ServiceTerms',
    component: () => import('~/pages/common/service-terms.vue'),
    meta: {
      title: 'service terms',
      hideInMenu: true,
    },
  },
]
