import type { AxiosError, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios'
import axios from 'axios'
import { notification } from 'ant-design-vue/es'
import { AES_ECB_ENCRYPT } from '~/utils/crypto'
import { STORAGE_AUTHORIZE_CODE_KEY, STORAGE_AUTHORIZE_KEY, useAuthorization, useAuthorizationCode } from '~/composables/authorization'
// import router from '~/router'

export interface ResponseBody<T = any> {
  code: string | number
  data?: T
  msg: string
}

const instance = axios.create({
  baseURL: import.meta.env.VITE_APP_BASE_API ?? '/',
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json',
  },
})

const requestHandler = async (config: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> => {
  const token = useAuthorization()
  const code = useAuthorizationCode()
  const { locale } = useI18nLocale()

  if (token.value)
    config.headers.set(`${STORAGE_AUTHORIZE_KEY}`, token.value)
  if (code.value)
    config.headers.set(`${STORAGE_AUTHORIZE_CODE_KEY}`, code.value)

  config.headers.set('Accept-Language', locale.value ?? 'zh-CN')

  // 获取用户时区
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  config.headers.set('X-Time-Zone', userTimeZone)

  return config
}

const responseHandler = (response: any): ResponseBody<any> | AxiosResponse<any> | Promise<any> | any => {
  const userStore = useUserStore()
  if (Number(response.data?.code) === 401) {
    /**
     * 这里处理清空用户信息和token的逻辑，后续扩展
     */
    userStore.clearUserInfo()
    userStore.openLoginModal()
  }
  return response.data
}

const errorHandler = (error: AxiosError): Promise<any> => {
  // const { notification } = useGlobalConfig()
  const userStore = useUserStore()

  if (error.response) {
    const { data, status, statusText } = error.response as AxiosResponse<ResponseBody>
    if (status === 401) {
      /**
       * 这里处理清空用户信息和token的逻辑，后续扩展
       */
      userStore.clearUserInfo()
      userStore.openLoginModal()
    }
    else if (status === 403) {
      // notification?.error({
      //   title: i18n.global.t('global.request.error.403'),
      //   content: data?.msg || statusText,
      //   duration: 3000
      // })
    }
    else if (status === 500) {
      // notification?.error({
      //   // title: i18n.global.t('global.request.error.500'),
      //   message: data?.msg || statusText,
      //   duration: 3000,
      // })
    }
    /* 413 请求实体过大 */
    else if (status === 413) {
      notification?.error({
        message: status,
        description: statusText || '请求文件过大, 请重新上传',
        // duration: 3000,
      })
    }
    else {
      notification?.error({
        message: status,
        description: `${data.msg}` || statusText,
        // duration: 3000,
      })
    }
  }
  return Promise.reject(error)
}

instance.interceptors.request.use(requestHandler)

instance.interceptors.response.use(responseHandler, errorHandler)

export default instance

export const useGet = <R = any, T = any>(url: string, params?: T, config?: AxiosRequestConfig): Promise<ResponseBody<R>> => {
  return instance.request({
    url,
    params,
    method: 'GET',
    ...config,
  })
}

export const usePost = < R = any, T = any>(url: string, data?: T, config?: AxiosRequestConfig): Promise<ResponseBody<R>> => {
  return instance.request({
    url,
    data,
    method: 'POST',
    ...config,
  })
}

export const usePostDown = <R = any, T = any>(url: string, data?: T, config?: AxiosRequestConfig): Promise<ResponseBody<R>> => {
  return instance.request({
    url,
    data,
    method: 'POST',
    ...config,
    responseType: 'blob',
  })
}

export const usePut = < R = any, T = any>(url: string, data?: T, config?: AxiosRequestConfig): Promise<ResponseBody<R>> => {
  return instance.request({
    url,
    data,
    method: 'PUT',
    ...config,
  })
}

export const useDelete = < R = any, T = any>(url: string, data?: T, config?: AxiosRequestConfig): Promise<ResponseBody<R>> => {
  return instance.request({
    url,
    data,
    method: 'DELETE',
    ...config,
  })
}

export const useUpload = < R = any, T = any>(url: string, data?: T, config?: AxiosRequestConfig): Promise<ResponseBody<R>> => {
  return instance.request({
    url,
    data,
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    timeout: 600000,
    withCredentials: false,
    ...config,
  })
}

export const usePostAes = <R = any, T = any>(url: string, data?: T, config?: AxiosRequestConfig): Promise<ResponseBody<R>> => {
  return instance.request({
    url,
    data,
    method: 'POST',
    ...config,
    transformRequest: [
      function (data: any) {
        return JSON.stringify({
          bodyData: AES_ECB_ENCRYPT(JSON.stringify(data)),
        })
      },
    ],
  })
}
