<script setup lang='ts'>
import Layout from './layout/index.vue'
import { EmittTaskKey, provideEmittTask } from '@/emitter/task'
// import { EmittImageKey, provideEmittImage } from '@/emitter/image'

defineOptions({
  name: 'Draw',
})

provide(EmittTaskKey, provideEmittTask())
// provide(EmittImageKey, provideEmittImage())
</script>

<template>
  <Layout />
</template>

<style lang='less' scoped>

</style>
