export const useTaskStore = defineStore('task', () => {
  /* 最大任务数 */
  const MaxTaskCount = shallowRef(3)
  const taskCodes = ref<string[]>([])
  /* 超过最大任务数，将不再发送绘图任务 */
  const taskFull = computed(() => taskCodes.value.length >= MaxTaskCount.value)

  const imageUrl = shallowRef('')

  /* 设置需要处理的图片，提供给其他功能使用 */
  const setPendingImageUrl = (url: string) => {
    imageUrl.value = url
    return url
  }

  /* 清除imageUrl */
  const clearPendingImageUrl = () => {
    imageUrl.value = ''
  }

  return {
    MaxTaskCount,
    taskFull,
    imageUrl,
    taskCodes,
    setPendingImageUrl,
    clearPendingImageUrl,
  }
})
