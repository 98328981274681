import { onUnmounted, ref } from 'vue'

export type RequestFunction<T, P> = (params?: P) => Promise<T>
type CallbackFunction<T> = (response: T) => void

export function usePoller<T, P = void>() {
  const interval = ref<number>(1000)
  const timer = ref<ReturnType<typeof setInterval> | null>(null)
  const callback = ref<CallbackFunction<T> | null>(null)
  const request = ref<RequestFunction<T, P> | null>(null)
  const doImmediate = ref<boolean>(false)
  const maxRetries = 3
  let retryCount = 0
  let requestParams: P | undefined

  const stopPolling = (): void => {
    if (timer.value) {
      clearInterval(timer.value)
      timer.value = null
    }
    retryCount = 0
  }

  const retry = async (): Promise<void> => {
    if (retryCount < maxRetries) {
      retryCount++
      await new Promise(resolve => setTimeout(resolve, 1000 * retryCount)) // 指数退避
      if (request.value && callback.value) {
        const response = await request.value(requestParams)
        callback.value(response)
      }
    }
    else {
      console.error('Max retry attempts reached')
    }
  }

  const start = async (): Promise<void> => {
    stopPolling()
    if (doImmediate.value && request.value && callback.value) {
      try {
        const response = await request.value(requestParams)
        callback.value(response)
      }
      catch (error) {
        console.error(error)
        await retry()
      }
      doImmediate.value = false
    }

    timer.value = setInterval(async () => {
      if (request.value && callback.value) {
        try {
          const response = await request.value(requestParams)
          callback.value(response)
        }
        catch (error) {
          console.error(error)
          await retry()
        }
      }
    }, interval.value)
  }

  const poll = (req: RequestFunction<T, P>, delay: number, cb: CallbackFunction<T>, immediate: boolean = false, params?: P): void => {
    request.value = req
    interval.value = delay
    callback.value = cb
    doImmediate.value = immediate
    requestParams = params
    start()
  }

  const stop = (): void => {
    stopPolling()
  }

  onUnmounted(() => {
    stopPolling()
  })

  return {
    poll,
    stop,
  }
}

export default usePoller
