const BASE_URL = '/AIGCImgComServ'

export interface UserInfo {
  // id: number
  permissions: string[] // 权限
  roles: (string | number)[] // 角色
  avatar: string
  accountAvailableCount: number
  accountInfo: string
}
// 个人信息
export const userInfoApi = () => {
  return usePost<UserInfo>(`${BASE_URL}/user/baseInfo/query/v1`)
}
