export function extractTitleAndList(text = '') {
  const title = text.split('#')[0]

  const regex = /#[^#]+#/g
  const listRaw = text.match(regex)

  const list = (listRaw || []).map(item => item.slice(1, -1).trim())

  const obj = {
    title,
    list,
  }
  return obj
}

export async function copyText(text: string): Promise<void> {
  if (navigator.clipboard) {
    try {
      await navigator.clipboard.writeText(text)
      console.log('Text copied to clipboard')
    }
    catch (err) {
      console.error('Failed to copy text: ', err)
      throw err
    }
  }
  else {
    // Fallback method using execCommand
    const textArea = document.createElement('textarea')
    textArea.value = text
    // Avoid scrolling to bottom
    textArea.style.top = '0'
    textArea.style.left = '0'
    textArea.style.position = 'fixed'

    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()

    try {
      const successful = document.execCommand('copy')
      if (successful) {
        console.log('Text copied to clipboard')
      }
      else {
        console.error('Fallback: Failed to copy text')
        throw new Error('Fallback: Failed to copy text')
      }
    }
    catch (err) {
      console.error('Fallback: Failed to copy text: ', err)
      throw err
    }
    finally {
      document.body.removeChild(textArea)
    }
  }
}

export async function getImageDimensions<T extends File | string>(
  input: T,
): Promise<{
    width: number
    height: number
  }> {
  return new Promise((resolve, reject) => {
    const img = new Image()

    img.onload = () => {
      resolve({
        width: img.width,
        height: img.height,
      })
    }

    img.onerror = () => {
      reject(new Error('Failed to load image'))
    }

    if (input instanceof File) {
      img.src = URL.createObjectURL(input)
    }
    else if (typeof input === 'string') {
      img.src = input
    }
    else {
      reject(new Error('Invalid input type'))
    }
  })
}
