<script setup lang="ts">
defineProps<{
  keepTransition?: boolean
  name?: string
}>()
const { layoutSetting } = storeToRefs(useAppStore())
</script>

<template>
  <RouterView>
    <template #default="{ Component, route }">
      <Transition appear :name="name || layoutSetting.animationName" mode="out-in">
        <KeepAlive v-if="route.meta?.keepAlive">
          <component :is="Component" :key="route.fullPath" />
        </KeepAlive>
        <component :is="Component" v-else :key="route.fullPath" />
      </Transition>
    </template>
  </RouterView>
</template>
