<script setup lang='ts'>
import { message } from 'ant-design-vue'
import { debounce } from 'lodash-es'
import { LoadingOutlined, VerticalAlignBottomOutlined } from '@ant-design/icons-vue'
import dayjs from 'dayjs'
import TaskList from './task-list/index.vue'
import { type DrawTaskInfo, type DrawTaskInfoParams, type ImageAuditResultVO, drawTaskListApi, drawTaskResultApi } from '~@/api/draw'
import type { EmittTask, TaskInfo } from '@/emitter/task'
import { EmittTaskKey } from '@/emitter/task'

import usePoller from '@/hooks/usePoller'
import EmptyImg from '@/assets/images/empty.webp'

interface Props {
  type: number
}

const props = withDefaults(defineProps<Props>(), {
  type: 1,
})

/*
  '文生图': 1,
  '图生图': 2,
  '局部重绘': 3,
  '外扩图': 4,
  '智能擦除': 5,
  '超分': 6,
  '风格识别': 7,
  '换发型': 12,
  '人物换脸': 13,
  '个性化写真': 14,
  '图转文': 15
  'Flux 生图': 17
 */
const TaskTypes = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  12,
  13,
  14,
  15,
  17,
]
const { t, locale } = useI18n()
/* 轮询结果状态 0:失败 1:进行中 2:已完成 3:排队中 4:超时 5: 任务上传中 */
const STOP_STATUS = [0, 2, 4]
const userStore = useUserStore()
const { taskCodes } = storeToRefs(useTaskStore())
const mittTask = inject<EmittTask>(EmittTaskKey)!
const currentTaskCode = shallowRef('')
const taskParams = reactive<DrawTaskInfoParams>({
  lastTaskCreateMinTime: null,
  lastCoreTaskMaxId: null,
  lastToolTaskMaxId: null,
  pageNum: 1,
  pageSize: 10,
  taskTypes: TaskTypes,
})
const { poll, stop } = usePoller<ImageAuditResultVO[], string[]>()

const scrollRef = ref<HTMLElement>()
const contentRef = ref<HTMLElement>()
const pageLoading = ref(true)
const dataSource = ref<Partial<DrawTaskInfo>[]>([])
const dataSourceLoading = ref(false)
const previousDataSource = ref<DrawTaskInfo[]>()
const isNextPage = ref(false)
const previousScrollHeight = ref(0)
const { height: contentHeight } = useElementSize(contentRef)
const { arrivedState } = useScroll(scrollRef)
const { bottom: bottomArrived, top: topArrived } = toRefs(arrivedState)
const isScrolled = computed(() => {
  if (scrollRef.value)
    return contentHeight.value > scrollRef.value.clientHeight
  return 0
})

const goBottom = (params: object = {}) => {
  const scrollContainer = scrollRef.value
  if (scrollContainer)
    scrollContainer.scrollTo({ top: scrollContainer.scrollHeight, ...params })
}

useResizeObserver(contentRef as any, (entries) => {
  const entry = entries[0]
  const { height } = entry.contentRect
  if (scrollRef.value && height < scrollRef.value?.clientHeight)
    return
  goBottom()
})

const requestFunction = async (taskCodes: string[] | undefined): Promise<ImageAuditResultVO[]> => {
  if (!taskCodes) {
    throw new Error('Task codes is empty')
  }

  // 这里调用 drawTaskResultApi
  const response = await drawTaskResultApi({ taskCodes })
  if (response.data) {
    return response.data // 访问 data 属性
  }
  else {
    throw new Error('Response data is undefined')
  }
}

const callbackFunction = (responses: ImageAuditResultVO[]) => {
  const taskCodesToRemove = responses.reduce((acc, response) => {
    const { roundStatus, taskCode } = response
    if (STOP_STATUS.includes(roundStatus)) {
      acc.add(taskCode)
    }
    return acc
  }, new Set<string>())

  /* 把占位的任务替换成真实的任务 */
  const taskMap = new Map(dataSource.value.map(item => [item.taskCode, item]))
  responses.forEach((response) => {
    const { taskCode, imageAuditResultVOList, drawingTaskProgressVO, caption, roundStatus, failReason } = response
    const task = taskMap.get(taskCode)
    if (task) {
      const progress = drawingTaskProgressVO?.progress
      task.progress = progress ? Math.floor(Number(progress) * 100) : 1
      task.result = imageAuditResultVOList
      task.caption = caption
      task.taskStatus = roundStatus
      task.failReason = failReason

      if (STOP_STATUS.includes(roundStatus)) {
        task.progress = 0
        userStore.getUserInfo()
      }
    }
  })

  taskCodes.value = taskCodes.value.filter(id => !taskCodesToRemove.has(id))

  // 所有任务完成后停止轮询
  if (taskCodes.value.length === 0) {
    stop()
  }
}

const startPolling = () => {
  poll(requestFunction, 2000, callbackFunction, true, taskCodes.value) // 每2秒轮询一次，立即发送一次请求
}

const drawTaskList = async (params: DrawTaskInfoParams) => {
  dataSourceLoading.value = true
  try {
    const { data, msg, code } = await drawTaskListApi(params)
    if (code === '200') {
      const { drawingTaskBarVOS, coreTaskMaxId, toolTaskMaxId, minCreateTime, nextPage } = data || {}
      dataSource.value.unshift(...(drawingTaskBarVOS || []))
      Object.assign(taskParams, {
        lastCoreTaskMaxId: coreTaskMaxId as number,
        lastToolTaskMaxId: toolTaskMaxId as number,
        lastTaskCreateMinTime: minCreateTime as string,
      })

      isNextPage.value = !!nextPage
    }
    else {
      message.error(msg)
    }
  }
  catch (error) {
    console.log('%c [ error ]-33', 'font-size:13px; background:pink; color:#bf2c9f;', error)
  }
  finally {
    dataSourceLoading.value = false
  }
}

const handleScroll = debounce(async () => {
  if (topArrived.value && isNextPage.value) {
    taskParams.pageNum += 1
    previousScrollHeight.value = contentHeight.value
    previousDataSource.value = dataSource.value as DrawTaskInfo[]

    await drawTaskList(toRaw(taskParams))

    setTimeout(() => {
      const hei = contentHeight.value - previousScrollHeight.value
      scrollRef.value?.scrollTo({ top: hei })
    }, 16.8)
  }
}, 300)

/* 当接收新任务时，则向dataSource添加一个空元素占位 */
const addPlaceholder = ({ taskCode, info }: TaskInfo) => {
  const createTime = info?.createTime ? info.createTime : dayjs().format('YYYY-MM-DD HH:mm:ss')
  dataSource.value.push({
    taskType: props.type,
    taskCode,
    result: [],
    progress: 1,
    prompt: '',
    ...info,
    createTime,
  })
}

const initLoad = async () => {
  if (!userStore?.isLogin) {
    pageLoading.value = false
  }
  else {
    await drawTaskList(toRaw(taskParams))
    pageLoading.value = false
  }
}

// 使用 watchEffect 来监听任务事件
watchEffect((onCleanup) => {
  initLoad()

  const callback = (taskInfo: TaskInfo) => {
    currentTaskCode.value = taskInfo.taskCode
    taskCodes.value.push(taskInfo.taskCode)
    addPlaceholder(taskInfo)
    stop() // 停止之前的轮询
    if (taskCodes.value.length) {
      startPolling()
    }
  }

  mittTask.onTask(callback)

  // 清理函数，在组件卸载或依赖改变时移除事件监听
  onCleanup(() => {
    mittTask.offTask(callback)
  })
})

// onMounted(initLoad)
</script>

<template>
  <div ref="scrollRef" class="task" @scroll="handleScroll">
    <div v-if="pageLoading" class="loading-wrapper">
      <LoadingOutlined />
    </div>

    <div v-if="dataSource.length" ref="contentRef" class="task-box">
      <TaskList :data-source="dataSource" :loading="dataSourceLoading" />
    </div>

    <div v-if="!dataSource.length" class="task-empty">
      <a-empty
        :image="EmptyImg"
        :image-style="{
          height: '120px',
        }"
      >
        <template #description>
          <span style="color: #666666;">
            <!-- 开启你的AI绘图体验之旅 -->
            {{ t('draw.task.greeting') }}
          </span>
        </template>
      </a-empty>
    </div>
    <a-float-button-group shape="circle" :style="{ right: '30px', bottom: '65px' }">
      <a-float-button v-show="isScrolled && !bottomArrived" @click="goBottom({ behavior: 'smooth' })">
        <template #icon>
          <VerticalAlignBottomOutlined />
        </template>
      </a-float-button>

      <a-tooltip placement="left" color="#fff">
        <a-float-button type="primary">
          <template #icon>
            <span class="text-[22px]">
              ?
            </span>
          </template>
        </a-float-button>
        <template #title>
          <template v-if="locale.includes('zh')">
            <div class="p-[15px] border-0 border-b-1 border-[#eef6fc] border-solid">
              <img class="w-[100px] h-[100px] object-contain" src="@/assets/images/publicCode.png">
            </div>
            <div class="text-[14px] p-[8px] text-[#333333]">
              扫码关注公众号 <br> 获取更多产品信息
            </div>
          </template>
          <template v-else>
            <div class="p-3 w-[120px] text-dark-4">
              Email us at
              <a href="mailto:contact@intellectart.ai" style="text-decoration: solid;" class="text-inherit! underline!">contact@intellectart.ai</a>
              for technical support.
            </div>
          </template>
        </template>
      </a-tooltip>
    </a-float-button-group>
  </div>
</template>

<style lang='less' scoped>
.task {
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  padding-right: 12px;
  border-radius: 4px;
  .loading-wrapper{
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  .task-empty {
    width: 100%;
    height: 100%;
    padding-top: 28%;
    background: #ffffff;
  }
  .task-box {
    width: 100%;
    height: fit-content;
    background: #ffffff;
    padding: 20px 20px;
  }
}
:deep(.ant-float-btn-primary) {
  background: linear-gradient(180deg, #2466eb 0%, #5a92ff 100%);
  .ant-float-btn-body {
    background: linear-gradient(180deg, #2466eb 0%, #5a92ff 100%);
  }
}
</style>
