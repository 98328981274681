import { ref } from 'vue'

export function useDownloadImage() {
  const isLoading = ref(false)
  const error = ref<string | null>(null)

  function extractFileNameFromUrl(url: string): string {
    try {
      const filename = url.substring(url.lastIndexOf('/') + 1)
      return filename
    }
    catch (error) {
      console.warn('Error extracting filename from URL:', error)
    }
    return 'image.png'
  }

  async function downloadImageAsBase64(imageUrl: string, fileName?: string): Promise<void> {
    isLoading.value = true
    error.value = null

    try {
      const img = new Image()
      img.crossOrigin = 'Anonymous'
      img.src = imageUrl

      await new Promise((resolve, reject) => {
        img.onload = resolve
        img.onerror = reject
      })

      const canvas: HTMLCanvasElement = document.createElement('canvas')
      canvas.width = img.width
      canvas.height = img.height

      const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d')
      if (!ctx) {
        throw new Error('Unable to get 2D context')
      }

      ctx.drawImage(img, 0, 0, img.width, img.height)

      const base64: string = canvas.toDataURL('image/png')

      const downloadLink: HTMLAnchorElement = document.createElement('a')
      downloadLink.href = base64
      downloadLink.download = fileName || extractFileNameFromUrl(imageUrl)

      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
      URL.revokeObjectURL(downloadLink.href)
    }
    catch (e) {
      console.error(e)
      error.value = e instanceof Error ? e.message : 'An unknown error occurred'
    }
    finally {
      isLoading.value = false
    }
  }

  async function downloadImageAsFile(imageUrl: string, mimeType: string = 'image/png'):
  Promise<void> {
    isLoading.value = true
    try {
      const response = await fetch(imageUrl)
      const blob = await response.blob()
      const fileName = extractFileNameFromUrl(imageUrl)
      const file = new File([blob], fileName, { type: mimeType })
      const url = URL.createObjectURL(file)
      const downloadLink: HTMLAnchorElement = document.createElement('a')
      downloadLink.href = url
      downloadLink.download = fileName
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
      URL.revokeObjectURL(url)
    }
    catch (e) {
      console.error(e)
      error.value = e instanceof Error ? e.message : 'An unknown error occurred'
    }
    finally {
      isLoading.value = false
    }
  }

  return {
    downloadImageAsBase64,
    downloadImageAsFile,
    isLoading,
    error,
  }
}
