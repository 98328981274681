<script setup lang="ts">
import type { ItemType } from 'ant-design-vue'
import type { Component } from 'vue'

export type MenuItem = ItemType & { type: number, icon?: Component, isNew?: boolean }

defineProps<{
  items: MenuItem[]
}>()

const emit = defineEmits<{
  (e: 'change', keys: (string | number)[]): void
  (e: 'select', item: any): void
}>()

const selectedKeys = defineModel<(string | number)[]>('selectedKeys', { default: () => [] })

const isSelected = (key: string | number) => {
  return selectedKeys.value.length > 0 && selectedKeys.value[0] === key
}

const handleItemClick = (item: MenuItem) => {
  const newSelectedKeys = [item.key]
  selectedKeys.value = newSelectedKeys
  emit('change', newSelectedKeys)
  emit('select', item)
}
</script>

<template>
  <div class="max-w-[300px]">
    <div
      v-for="item in items" :key="item.key"
      class="cursor-pointer text-4 flex gap-1 flex-col justify-center items-center w-full p-2 transition duration-200 ease-in-out rounded-1 mb-3 bg-[#f0f4fd] select-none last:mb-0 relative" :class="[
        isSelected(item.key) ? 'bg-[#217df2]! text-white!' : 'hover:bg-[#e5ecfb] text-[#333333]',
      ]"
      @click="handleItemClick(item)"
    >
      <component :is="item.icon" v-if="item.icon" />
      <span>{{ item.label }}</span>
      <span v-if="item.isNew" class="absolute top-0 right-0 font-600 text-white text-xs px-3 rounded-bl-1 rounded-tr-1 bg-gradient-to-l from-[#FFB247] to-[#E78C0E]">New</span>
    </div>
  </div>
</template>

<style scoped>

</style>
