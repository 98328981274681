import type { RouteRecordRaw } from 'vue-router'
import { openRoutes } from '~@/router/menu-routes'

export const ROOT_ROUTE_REDIRECT_PATH = '/home'
const Layout = () => import('~/layouts/index.vue')

export default [
  {
    path: '/',
    name: 'RootPath',
    redirect: ROOT_ROUTE_REDIRECT_PATH,
    component: Layout,
    children: [...openRoutes],
  },
  // {
  //   path: '/login',
  //   name: 'Login',
  //   component: () => import('~/pages/login/index.vue'),
  //   meta: {
  //     title: '登录',
  //   },
  // },
  {
    path: '/common',
    name: 'LayouBasicRedirect',
    component: Layout,
    redirect: '/redirect',
    children: [
      {
        path: '/redirect/:path(.*)',
        name: 'redirect',
        component: () => import('~/pages/common/redirect.vue'),
      },
    ],
  },
  {
    path: '/401',
    name: 'Error401',
    component: () => import('~/pages/exception/401.vue'),
    meta: {
      title: '授权已过期',
    },
  },
  {
    path: '/error',
    name: 'Error',
    component: () => import('~/pages/exception/error.vue'),
    meta: {
      title: '服务报错',
    },
  },
  {
    path: '/:pathMatch(.*)',
    component: () => import('~/pages/exception/404.vue'),
  },
  {
    path: '/filePreview',
    name: 'FilePreview',
    component: () => import('~/pages/exception/filePreview.vue'),
    meta: { title: '文件预览' },
  },
] as RouteRecordRaw[]
