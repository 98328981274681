<script setup lang='ts'>
import type { ItemType, MenuProps } from 'ant-design-vue'
import ContentLayout from '../components/content-layout.vue'
import DrawTask from '../components/draw-task.vue'
import Menu from './menu-en.vue'
import SvgIcon from '@/components/svg-icon/index.vue'
import RouteView from '~/pages/common/route-view.vue'

type MenuItem = ItemType & { type: number, isNew?: boolean }

// const TASK_TYPES = {
// 'Flux生图': 17
// '文生图': 1,
// '图生图': 2,
// '局部重绘': 3,
// '外扩图': 4,
// '智能擦除': 5,
// '超分': 6,
// '风格识别': 7,
// '换发型': 12,
// '人物换脸': 13,
// '个性化写真': 14,
// '图转文': 15
// }

const { t } = useI18n()

const menuItems: MenuItem[] = [
  {
    label: t('draw.menu.flux-ai-image'), // Flux 生图
    key: 'FluxAIImage',
    type: 17,
    isNew: true,
    icon: () => h(SvgIcon, { name: 'fluxAiImage', style: { fontSize: '16px' } }),
  },
  {
    label: t('draw.menu.text_to_image'), // 文生图
    key: 'TextToImage',
    type: 1,
    icon: () => h(SvgIcon, { name: 'text2img', style: { fontSize: '16px' } }),
  },
  {
    label: t('draw.menu.image_to_image'), // 图生图
    key: 'ImageToImage',
    type: 2,
    icon: () => h(SvgIcon, { name: 'img2img', style: { fontSize: '16px' } }),
  },
  {
    label: t('draw.menu.inpainting'), // 局部重绘
    key: 'PartialRedraw',
    type: 3,
    icon: () => h(SvgIcon, { name: 'inpaintAnything', style: { fontSize: '16px' } }),
  },
  {
    /* 外扩图 */
    label: t('draw.menu.extender'),
    key: 'OutwardExpansion',
    type: 4,
    icon: () => h(SvgIcon, { name: 'outward', style: { fontSize: '16px' } }),
  },
  {
    /* 智能擦除 */
    label: t('draw.menu.erase'),
    key: 'IntelligentErase',
    type: 5,
    icon: () => h(SvgIcon, { name: 'eraserLayout', style: { fontSize: '16px' } }),
  },
  {
    /* 图像超分 */
    label: t('draw.menu.enlarger'),
    key: 'ImageEnhance',
    type: 6,
    icon: () => h(SvgIcon, { name: 'imageEnhance', style: { fontSize: '16px' } }),
  },
  {
    /* 换发型 */
    label: t('draw.menu.hair'),
    key: 'HairPaint',
    type: 12,
    icon: () => h(SvgIcon, { name: 'hairStyle', style: { fontSize: '16px' } }),
  },
  {
    /* 人像换脸 */
    label: t('draw.menu.facial'),
    key: 'PortraitFacelift',
    type: 13,
    icon: () => h(SvgIcon, { name: 'faceChang', style: { fontSize: '16px' } }),
  },
  {
    /* 个性化写真 */
    label: t('draw.menu.portrait'),
    key: 'PersonalizedPs',
    type: 14,
    icon: () => h(SvgIcon, { name: 'certificates', style: { fontSize: '16px' } }),
  },
  {
    /* 图转文 */
    label: t('draw.menu.image_to_prompt'),
    key: 'ImageToText',
    type: 15,
    icon: () => h(SvgIcon, { name: 'imageToText', style: { fontSize: '16px' } }),
  },
]
const { layoutSetting } = storeToRefs(useAppStore())
const headerHeight = layoutSetting.value.headerHeight
const router = useRouter()
const route = useRoute()
const selectedKeys = ref<string[]>([])
const currentMenuItem = computed(() => menuItems.find(item => item.key === selectedKeys.value[0]))

const handleSelect: MenuProps['onSelect'] = (e) => {
  router.push({ name: e.key as string })
}

watch(
  () => route.name,
  (routeName) => {
    const currentMenuItem = menuItems.find(item => item?.key === routeName)
    if (currentMenuItem) {
      selectedKeys.value = [currentMenuItem.key as string]
    }
  },
  {
    immediate: true,
  },
)
</script>

<template>
  <div class="bg-[#f5f6fc] overflow-hidden flex" :style="{ height: `calc(100vh - ${headerHeight}px)` }">
    <div class="w-[180px] py-[12px]">
      <div class="h-full flex flex-col gap-20 pl-2 pr-[6px] overflow-y-auto [scrollbar-gutter:stable]">
        <!-- <a-menu
        id="draw-menu"
        v-model:selectedKeys="selectedKeys"
        style="width: 100%"
        class="bg-transparent! border-none!"
        mode="inline"
        :items="menuItems"
        @select="handleSelect"
      /> -->
        <Menu
          v-model:selectedKeys="selectedKeys"
          :items="menuItems"
          @select="handleSelect"
        />
      </div>
    </div>
    <div class="flex-1 flex overflow-hidden">
      <ContentLayout>
        <template #left>
          <RouteView />
        </template>
        <template #right>
          <DrawTask :type="currentMenuItem?.type" />
        </template>
      </ContentLayout>
    </div>
  </div>
</template>

<style lang='less' scoped>
:deep(.ant-menu-item) {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1px;
  border-radius: 3px !important;
  margin-bottom: 15px !important;
}
:deep(.ant-menu-item-selected) {
  background-color: #4683ff !important;
  color: white !important;
}
// .toolTipCode{
//     :deep(.ant-tooltip-inner){
//         color: rgb(238, 246, 252)
//     }
// }
</style>
