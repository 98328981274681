import { toArray } from '@v-c/utils'
import type { AccessEnum } from '~@/utils/constant'

export function useAccess() {
  const userStore = useUserStore()
  const roles = computed(() => userStore.roles)
  const hasAccess = (roleList: (string | number)[] | string | number | AccessEnum) => {
    // const userStore = useUserStore()
    const accessRoles = userStore.roles
    const roleArr = toArray(roleList).flat(1)
    return roleArr.some(role => accessRoles?.includes(role))
  }
  return {
    hasAccess,
    roles,
  }
}

/**
 * @description: permissions
 */

export function usePermissions() {
  const { permissions } = storeToRefs(useUserStore())
  const hasPermission = (permissionList: string [] | string) => {
    const permissionArr = toArray(permissionList).flat(1)
    return permissionArr.some(permission => permissions.value?.includes(permission))
  }
  return {
    hasPermission,
    permissions,
  }
}
