const BASE_URL = '/AIGCImgComServ'

export interface ControlnetParams {
  cntModelCode: string
  controlImageUrl: string
  controlMode: number
  controlWeight: number
  enabled: boolean
  guidanceEnd: number
  guidanceStart: number
  preprocessor: string
  previewImageUrl: string
  resizeMode: number
}

export interface DrawParams {
  baseModelCustomCode: string // 基础模型自定义编码
  originPrompt: string // 原始提示词
  prompt: string // 正向提示词
  negativePrompt: string // 反向提示词
  promptGuideFactor: number // 提示词相关性
  sampleStep: number // 采样次数
  samplerName: string // 采样器
  seed: number // 随机种子
  singleBatchSize: number // 单批次图像生成数量
  targetImgHigh: number // 生成尺寸高度，单位：像素
  targetImgWidth: number // 生成尺寸宽度，单位：像素
  clipLayer: number // CLIP跳过层层
  vaeModelCode: string // VAE模型编码
  sourceImageUrl?: string // 源图片地址
  maskImageUrl?: string // 蒙层图片地址
  redrawStrength?: number // 重绘强度
  cntPluginItemParameterV2DTOS?: Array<ControlnetParams>
  hrEnlargeAlgorithm?: string // 高清修复算法
  hrRedrawSampleStep?: number // 高清修复重绘采样步数
  hrRedrawStrength?: number // 高清修复重绘强度
  hrScaleFactor?: number // 高清修复目标图等比缩放倍数
  enableHr?: boolean // 是否开启高清修复
}

export interface DrawResult {
  roundCount: number
  roundPeriod: number
  roundStart: number
  taskCode: string
  createTime?: string
}

/* 单个任务查询的结果 */
export interface DrawTaskSingleInfo {
  description: string
  drawingTaskProgressVO: DrawingTaskProgressVO
  imageData: Array<string>
  result: Array<ImageResult>
  styleIdentifyResult: Array<StyleIdentifyResult>
  taskCode: string
  taskStatus: number // 任务状态 0:失败 1:进行中 2:已完成 3:排队中 5: 任务上传中
}

/**
 * @description: 绘图任务进展状态
 * @param {string} taskCode explain
 */
export const drawTaskStatusApi = (params: { taskCode: string }) => {
  return usePost<DrawTaskSingleInfo, { taskCode: string }>(`${BASE_URL}/drawing/task/status/query/v1`, params)
}

/**
 * @description: 文生图
 * @param {DrawParams} params 文生图参数
 */
export const text2ImgApi = (params: DrawParams) => {
  return usePost<DrawResult, DrawParams>(`${BASE_URL}/drawing/core/text2Img/v2`, params)
}

export type FluxDrawParams = Pick<DrawParams, 'prompt' | 'targetImgHigh' | 'targetImgWidth' | 'seed'> & { quality: 1 | 2 }

/*
  @description: Flux-AI 图生图
  @params {DrawParams} params 图生图参数
*/
export const fluxGenerateImgApi = (params: FluxDrawParams) => {
  return usePost<DrawResult, FluxDrawParams>(`${BASE_URL}/drawing/tool/v1/flux/txt2Img`, params)
}

/**
 * @description: 图生图
 * @param {DrawParams} params 图生图参数
 */

export const image2ImgApi = (params: DrawParams) => {
  return usePost<DrawResult, DrawParams>(`${BASE_URL}/drawing/core/image2Img/v2`, params)
}

/**
 *
 * @description 局部重绘
 */
export const partialRedrawApi = (data: DrawParams) => usePost<DrawResult, DrawParams>(`${BASE_URL}/drawing/core/imgRedraw/v2`, data)

/* 采样器查询 */

export const samplerApi = () => {
  return usePost<Array<string>>(`${BASE_URL}/configs/sampler/query/v1`)
}

/*
VAE查询
*/

export const vaeApi = () => {
  return usePost<Array<string>>(`${BASE_URL}/configs/vae/query/v1`)
}

/*
提示词扩写
*/

export const promptExpandApi = (data: { prompts: string }) => {
  return usePost<any, { prompts: string }>(`${BASE_URL}/drawing/prompts/expand/v1`, data)
}

/*
提示词生成
*/
export interface PromptTagValueVO {
  promptTagValue: string
  promptTagTranslateValue: string
}

export interface PromptTagObject {
  promptTag: string
  promptTagValueVOS: PromptTagValueVO[]
}

export const promptGenerateApi = () => {
  return usePost<PromptTagObject[]>(`${BASE_URL}/drawing/prompts/query/v1`)
}

/*
提示词翻译
*/

export const promptTranslateApi = (data: { prompts: string }) => {
  return usePost<any, { prompts: string }>(`${BASE_URL}/drawing/prompts/translate/v1`, data)
}

export interface DrawTaskInfoParams {
  lastTaskCreateMinTime: string | null // 创建时间
  lastCoreTaskMaxId: number | null // 最新核心任务ID
  lastToolTaskMaxId: number | null // 最新工具任务ID
  pageNum: number // 分页页码
  pageSize: number // 分页大小
  taskTypes: Array<number> // 任务类型
}

export interface DrawTaskResult {
  imageAuditStatus: boolean // 图片审核状态
  imageModerationResponseBodyDataResults: Array<{
    confidence: number // 置信度
    label: string // 标签
  }>
  imageStatue: number // 图片状态
  seed: number // 种子
  targetImageUrl: string // 目标图片地址
}

export interface ImageResult {
  imageAuditStatus: boolean
  imageModerationResponseBodyDataResults: Array<{
    confidence: number
    label: string
  }>
  imageStatue: number
  seed: number
  targetImageUrl: string
}

export interface StyleIdentifyResult {
  modelClassifyName: string // 模型分类名称
  styleIdentifyResultItemBOS: Array<{
    modelClassify: number // 模型分类
    modelCustomCode: string // 模型自定义编码
    modelCustomName: string // 模型自定义名称
    modelName: string // 模型名称
    similarity: number // 相似度
  }>
}

export interface DrawTaskInfo {
  createTime: string | null // 创建时间
  prompt: string | null // 提示词
  result: Array<ImageResult> | null
  styleIdentifyResult: Array<StyleIdentifyResult> | null
  taskCode: string | null // 任务编号
  taskStatus: number | null // 任务状态
  taskType: number | null // 任务类型
  caption: string | null
  progress?: number
  failReason: string | null // 失败原因
}

export interface DrawTaskInfoList {
  minCreateTime: string // 创建时间
  drawingTaskBarVOS: Array<DrawTaskInfo>
  coreTaskMaxId: number // 最新核心任务ID
  toolTaskMaxId: number // 最新工具任务ID
  nextPage: boolean // 是否有下一页
}

/*
绘图任务列表信息查询
*/

export const drawTaskListApi = (data: DrawTaskInfoParams) => {
  return usePost<DrawTaskInfoList, DrawTaskInfoParams>(`${BASE_URL}/drawing/task/bar/info/query/v1`, data)
}

/*
绘图任务对话栏批量轮询结果查询
*/

export interface DrawingTaskProgressVO {
  currentImage: string
  jobCount: number
  jobNo: number
  progress: string
}

export interface ImageAuditResultVOList {
  imageAuditStatus: boolean
  imageModerationResponseBodyDataResults: Array<{
    confidence: number
    label: string
  }>
  imageStatue: number
  seed: number
  targetImageUrl: string
}

export interface ImageAuditResultVO {
  drawingTaskProgressVO: DrawingTaskProgressVO
  imageAuditResultVOList: Array<ImageAuditResultVOList>
  roundStatus: 1 | 2 | 3 | 4 // 轮询结果状态 1: 成功 2: 失败 3: 加载中 4: 超时
  taskCode: string
  taskType: number
  msg: string
  caption: string | null // 针对图转文的字段
  failReason: string | null // 失败原因
}

export const drawTaskResultApi = (data: { taskCodes: Array<string> }) => {
  return usePost<ImageAuditResultVO[], { taskCodes: Array<string> }>(`${BASE_URL}/drawing/task/bar/result/round/v1`, data)
}

export const fusionFaceApi = (data?: object) => usePost(`${BASE_URL}/drawing/tool/face/fusion/v1`, data)

export const PersonalizedPsApi = (data?: object) => usePost(`${BASE_URL}
/drawing/tool/individualized/portrait/v1`, data)

// 智能消除
export const eraserApi = (data?: object) => {
  return usePost(`${BASE_URL}/drawing/tool/autoErase/v1`, data)
}
// 图转文
export const imageToTextApi = (data?: object) => {
  return usePost(`${BASE_URL}/drawing/tool/image/covert/text/v1`, data)
}

// 图像超分
export const enhanceImageApi = (data?: object) => {
  return usePost(`${BASE_URL}/drawing/tool/resolution/enhance/v2`, data)
}

// 放大算法
export const queryAlgorithmApi = (data?: object) => {
  return usePost(`${BASE_URL}/configs/resolution/algorithm/v1`, data)
}

// 换发型
export const hairPaintApi = (data?: object) => {
  return usePost(`${BASE_URL}/drawing/tool/hair/change/v1`, data)
}

export interface OutwardExpansion {
  horizontalExpansionRatio: number
  imageCount: number
  negativePrompt: string
  prompt: string
  promptGuideFactor: number
  sampleStep: number
  samplerName: string
  seed: number
  sourceImage: string
  verticalExpansionRatio: number
}

// 图片外扩
export const outwardExpansionApi = (data?: object) => {
  return usePost(`${BASE_URL}/drawing/tool/imgExpand/v1`, data)
}
