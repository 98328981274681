import type { LayoutSetting } from '~@/stores/app'

const language = import.meta.env.VITE_LANGUAGE

export default {
  title: '智启特AI',
  theme: 'light',
  logo: language.includes('en') ? '/logo.png' : '/logo_zh.png',
  collapsed: false,
  drawerVisible: false,
  colorPrimary: '#2466EB',
  layout: 'top',
  contentWidth: 'Fluid',
  fixedHeader: true,
  fixedSider: true,
  splitMenus: false,
  header: true,
  menu: true,
  menuHeader: true,
  footer: false,
  colorWeak: false,
  multiTab: false,
  multiTabFixed: true,
  headerHeight: 80,
  copyright: '',
  animationName: 'fadein',
} as LayoutSetting
