import { createPinia } from 'pinia'
import { createApp } from 'vue'
import { createGtm } from '@gtm-support/vue-gtm'
import type { App } from 'vue'
import type { Router } from 'vue-router'
import Root from './App.vue'
import { setupI18n } from './locales'
import {
  setupAccessDirective,
} from './directive'
import sentryInit from './utils/sentry-sdk'
import router from '~/router'
import 'virtual:svg-icons-register'
import '~/router/router-guard'
import 'ant-design-vue/dist/reset.css'
import '~/assets/styles/reset.css'
import 'uno.css'

const pinia = createPinia()

// 注册指令
function setupDirective(app: App) {
  setupAccessDirective(app)
}

// gmt 初始化
function setupGtm(app: App, router: Router) {
  if (import.meta.env.VITE_GTM_ID) {
    app.use(createGtm({
      id: import.meta.env.VITE_GTM_ID,
      vueRouter: router,
      enabled: true,
    }))
  }
}
async function start() {
  const app: App = createApp(Root)
  app.use(pinia)
  await setupI18n(app)
  setupDirective(app)
  sentryInit(app, router)
  app.use(router)
  setupGtm(app, router)
  app.mount('#app')
  app.config.performance = true
}

start()
