export class Updater {
  oldScript: string[] // 存储第一次值也就是script 的hash 信息
  newScript: string[] // 获取新的值 也就是新的script 的hash信息

  constructor() {
    this.oldScript = []
    this.newScript = []
    this.init() // 初始化
  }

  async init() {
    const html: string = await this.getHtml()
    this.oldScript = this.parserScript(html)
  }

  async getHtml() {
    try {
      const html = await fetch(`/?_timestamp=${Date.now()}`).then(res => res.text()) // 读取index html
      return html
    }
    catch (error) {
      console.error('Error occurred while fetching HTML:', error)
      throw error
    }
  }

  parserScript(html: string) {
    const reg = /<script(?:\s[^>]*)?>(.*?)<\/script\s*>/gi // script正则
    const scriptArr = html.match(reg) as string[] // 匹配script标签
    return scriptArr
  }

  findVersion(arr: string[]) {
    const result = arr.find(script => /index/i.test(script))
    if (result) {
      const regex = /index-(.*?)\.js/
      const match = result.match(regex)
      if (match && match.length > 1) {
        const number = match[1]
        return { number }
      }
    }
  }

  async checkUpdate() {
    try {
      const newHtml = await this.getHtml()
      const scriptArr = this.parserScript(newHtml)
      const number = this.findVersion(scriptArr)
      return number
    }
    catch (error) {
      console.error('Error occurred during update check:', error)
    }
  }
}
